import React, { Component } from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"

class Post extends Component {
  state = {

  }

  componentDidMount() {
  }

  postTitle(title) {
    return <span dangerouslySetInnerHTML={{ __html: title}} />
  }
  postContent(content) {
    return <div dangerouslySetInnerHTML={{ __html: content }} />
  }

  render() {
    const post = this.props.data.allWordpressPost.edges[0].node
    const title = this.postTitle(post.title)
    const content = this.postContent(post.content)
    let featuredImage = ""

    if(post.featured_media) {
      featuredImage = <img className="w-full" src={post.featured_media.source_url} />
    }


    return (
      <div className="m-6">
        <div className="md:flex lg:flex xl:flex mb-6">
          <div className={`w-full md:w-4/12 lg:w-4/12 xl:w-4/12 p-4 h-0-pc border border-r-0 xl:border-r-0 cursor-pointer`} style={{boxShadow: '1px 0 0 0 #C2C2C2'}}>
            {featuredImage}
          </div>


          <div className="w-full md:w-7/12 lg:w-7/12 xl:w-7/12">
            <div className={`text-3xl md:text-4xl lg:text-4xl xl:text-4xl uppercase p-4 border border-t-0 md:border-t-1 lg:border-t-1 xl:border-t-1 block`}>
              {title}
            </div>
            <div className="content block w-full md:w-2/3 lg:w-2/3 xl:w-2/3 p-6 border border-t-0 text-sm">
              {content}
            </div>
          </div>
        </div>
      </div>

    )

  }



}

export const query = graphql`
query($id: String!) {
  allWordpressPost(filter: {id: {eq: $id}}) {
    edges {
      node {
        slug
        status
        content
        date
        title
        type
        featured_media {
          source_url
        }
      }
    }
  }
}
`

export default Post
